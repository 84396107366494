<template>
  <div class="doc-form-container clearfix">
    <!-- ข้อมูลลูกค้า -->
    <b-row class="project-row">
      <b-col>
        <!-- เลือกประเภท -->
        <b-form-group>
          <b-form-radio-group
            v-model="form.selectedType"
            :options="options"
            inline
          >
          </b-form-radio-group>
        </b-form-group>

        <div v-if="form.selectedType === 'person'">
          <!-- fields for บุคคล -->
        </div>

        <div v-if="form.selectedType === 'company'">
          <!-- fields for บริษัท -->
        </div>
        <!-- ชื่อลูกค้า -->
        {{ form.member }}
        <b-form-group label="ชื่อ-นามสกุล">
          <b-form-input
            v-model="form.customer_name"
            type="text"
            pattern="\d*"
            data-model-name="reference"
            placeholder="ชื่อลูกค้า"
          />
        </b-form-group>
        <!-- ข้อมูลลูกค้า -->
        <b-form-group label="ที่อยู่">
          <b-form-textarea
            v-model="form.billing_address"
            rows="3"
            class="no-resize contact-address"
            style="height: 80px; overflow-y: auto; resize: none"
            placeholder="รายละเอียดที่อยู่"
          >
          </b-form-textarea>
        </b-form-group>
        <!-- Zipcode -->
        <b-form-group label="รหัสไปรษณีย์">
          <b-form-input
            type="text"
            maxlength="5"
            pattern="\s*"
            class="form-control contact-zipcode"
            placeholder="รหัสไปรษณีย์"
            v-model="form.zipcode"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Tax ID -->
        <b-form-group label="เลขประจำตัวผู้เสียภาษี">
          <b-form-input
            type="text"
            maxlength="13"
            pattern="\s*"
            class="form-control contact-tax-id"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            v-model="form.card_id"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Branch -->
        <b-form-group
          v-if="form.selectedType === 'company'"
          label="สำนักงาน/สาขาเลขที่"
        >
          <b-form-input
            type="text"
            class="form-control contact-branch"
            placeholder="สำนักงาน/สาขาเลขที่"
            v-model="form.branch"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- <div class="text">จำนวนเงินรวมทั้งสิ้น</div>
        <div class="total-value"><h2>13123</h2></div> -->
        <!-- เลขที่ใบกำกับภาษี -->
        <h5 class="mb-2">TAX INVOICE No. {{ tax_invoice_no }}</h5>

        <!-- tax_invoice_no: "",
        yearmonth: "",
        invoice_number: "", -->
        <b-row>
          <b-col>
            <b-form-input
              autocomplete="off"
              class="border rounded"
              v-model="form.yearmonth"
              @change="get_lastest(form.yearmonth)"
            />
          </b-col>
          <b-col>
            <b-form-input
              autocomplete="off"
              class="border rounded"
              v-model="form.invoice_number"
            />
          </b-col>
        </b-row>

        <!-- วันที่ -->
        <b-form-group label="วันที่">
          <date-picker
            v-model="form.date"
            :masks="{ input: 'D/M/YYYY' }"
            :model-config="datepickerConfig"
            mode="date"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <!-- :mode="date-time" -->
              <b-form-input
                autocomplete="off"
                class="border px-2 rounded"
                :value="inputValue"
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </b-form-group>

        <b-row>
          <b-col>
            <!-- เอกสารอ้างอิง -->
            <b-form-group label="เอกสารอ้างอิง">
              <b-form-input
                v-model="form.order_id"
                type="text"
                pattern="\d*"
                data-model-name="reference"
                placeholder="ชื่อลูกค้า"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- ผู้ขาย -->
            <b-form-group label="ผู้ขาย">
              <b-form-input
                v-model="form.seller_name"
                type="text"
                data-model-name="reference"
                placeholder="ผู้ขาย"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="form.selectedType === 'company'">
            <!-- ผู้ติดต่อ -->
            <b-form-group label="ผู้ติดต่อ">
              <b-form-input
                v-model="form.contact_name"
                type="text"
                data-model-name="reference"
                placeholder="ผู้ติดต่อ"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- เบอร์โทร -->
            <b-form-group label="เบอร์โทร">
              <b-form-input
                v-model="form.contact_phone"
                type="text"
                pattern="\d*"
                data-model-name="reference"
                placeholder="เบอร์โทร"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- อุปกรณ์ -->
    <b-row class="mb-2">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th style="width: 50%">รายละเอียด</th>
            <th>
              <b-form-input type="text" v-model="form.quantity_text" />
              <small>จำนวน</small>
            </th>
            <!-- <th>จำนวน</th> -->
            <!-- <th>ราคาต่อหน่วย</th> -->
            <th>
              <b-form-input type="text" v-model="form.price_text" />
              <small>ราคาต่อหน่วย</small>
            </th>
            <th>คิดเป็นเงิน</th>
            <th>ลบ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.items" :key="item.index">
            <td>{{ index + 1 }}</td>
            <td>
              <b-form-input type="text" v-model="item.item_name" />
            </td>
            <td>
              <b-form-input type="text" v-model="item.duration" />
            </td>
            <td>
              <b-form-input type="text" v-model="item.rental_price" />
            </td>
            <td>
              {{ computedTotal(item) }}
            </td>
            <td>
              <b-button variant="danger" @click="deleteItem(index)" size="sm">
                <feather-icon icon="XIcon" />
              </b-button>
            </td>
          </tr>
          <tr>
            <td>{{ form.items.length + 1 }}</td>
            <td>
              <b-form-input type="text" v-model="newRow.product.item_name" />
            </td>
            <td>
              <b-form-input type="text" v-model="newRow.product.duration" />
            </td>
            <td>
              <b-form-input type="text" v-model="newRow.product.rental_price" />
            </td>
            <td>{{ computedTotal(newRow) }}</td>
            <td>
              <b-button variant="success" @click="addNewItem" size="sm">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
    <hr />
    <b-row class="mb-2">
      <b-col lg="6" cols="12">
        <!-- จำนวนเงินรวมทั้งสิ้นตัวหนังสือ -->
        <b-form-group label="จำนวนเงินรวม ตัวหนังสือไทย">
          <b-form-input
            type="text"
            class="form-control"
            placeholder="จำนวนเงินรวม ตัวหนังสือไทย"
            v-model="thaiBahtText"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" cols="12">
        <!-- รวมเป็นเงิน -->
        <div class="d-flex justify-content-between">
          <h4>รวมเป็นเงิน</h4>
          <h4>{{ display_sum_total | number }} บาท</h4>
        </div>

        <!-- ส่วนลด -->
        <div class="d-flex justify-content-between">
          <h4>ส่วนลด</h4>
          <h4>{{ display_discount | number }} บาท</h4>
        </div>

        <!-- จำนวนเงินหลังหักส่วนลด -->
        <div class="d-flex justify-content-between">
          <h4>จำนวนเงินหลังหักส่วนลด</h4>
          <b-form-input
            type="text"
            class="form-control ml-2 text-right"
            placeholder="จำนวนเงินหลังหักส่วนลด"
            v-model="after_discount_price"
            required
          >
          </b-form-input>
        </div>

        <!-- ภาษีมูลค่าเพิ่ม -->
        <div class="d-flex justify-content-between">
          <h4>ภาษีมูลค่าเพิ่ม</h4>
          <h4>{{ vat | number }} บาท</h4>
        </div>

        <!-- ราคาไม่รวมภาษีมูลค่าเพิ่ม -->
        <div class="d-flex justify-content-between">
          <h4>ราคาไม่รวมภาษีมูลค่าเพิ่ม</h4>
          <h4>{{ price_before_vat | number }} บาท</h4>
        </div>

        <!-- หัก ณ ที่จ่าย -->
        <div class="d-flex justify-content-between">
          <h4>% หัก ณ ที่จ่าย</h4>
          <b-form-radio-group
            v-model="form.selectedWithHolding"
            :options="options2"
            inline
          >
          </b-form-radio-group>
        </div>

        <!-- ยอดหัก ณ ที่จ่าย -->
        <div class="d-flex justify-content-between">
          <h4>หัก ณ ที่จ่าย</h4>
          <h4>{{ with_holding_price | number }}</h4>
        </div>

        <!-- ยอดชำระ -->
        <div class="d-flex justify-content-between">
          <h4>ยอดชำระ</h4>
          <h4>{{ after_with_holding_price }}</h4>
        </div>

        <!-- รูปแบบชำระเงิน -->
        <div class="d-flex justify-content-between">
          <h4>รูปแบบการชำระเงิน</h4>

          <div>
            <b-form-radio-group
              v-model="form.payment_method"
              :options="paymentOptions"
              inline
              class="mb-1"
              require
            >
            </b-form-radio-group>
            <!-- show_other_box -->
            <b-form-input
              type="text"
              class="form-control text-right"
              placeholder="ประเภทการชำระเงิน"
              v-model="other_payment_method"
              required
              v-if="show_other_box"
            >
            </b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div></div>
          <b-button
            variant="success"
            class="mt-1 text-right"
            @click="editTaxInvoice()"
            :disabled="submitDisable"
          >
            แก้ไขใบกำกับภาษี
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import {
  BAlert,
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BAlert,
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BFormTextarea,
    DatePicker,
  },

  computed: {
    display_sum_total() {
      // รวมเป็นเงิน รวมเลขจากตาราง
      if (this.form.items.length > 0) {
        const totalSum = this.form.items.reduce((sum, item) => {
          return sum + item.duration * item.rental_price;
        }, 0);
        return totalSum;
      }
    },

    after_discount_price: {
      get() {
        // If there's a manual input, return that; otherwise, compute it normally
        const temp =
          this.form2.manual_after_discount_price !== null
            ? this.form2.manual_after_discount_price
            : this.display_sum_total - this.form2.discount_price;

        return temp;
      },
      set(value) {
        // When user changes the value, update the manual input tracker
        this.form2.manual_after_discount_price = value;
      },
    },

    display_discount() {
      return this.display_sum_total - this.after_discount_price;
    },

    order_discount() {
      return this.form.discount_price;
    },

    price_before_vat() {
      const totalNetPrice = this.after_discount_price / 1.07;
      const roundedPrice = Math.round(totalNetPrice * 100) / 100; // Round to 2 decimal places
      return roundedPrice.toFixed(2); // Format as a string with 2 decimal places
    },

    vat() {
      const temp = this.after_discount_price - this.price_before_vat;
      return temp.toFixed(2);
    },

    thaiBahtText() {
      let Number = this.CheckNumber(this.after_discount_price);
      const NumberArray = [
        "ศูนย์",
        "หนึ่ง",
        "สอง",
        "สาม",
        "สี่",
        "ห้า",
        "หก",
        "เจ็ด",
        "แปด",
        "เก้า",
        "สิบ",
      ];
      const DigitArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"];
      let BahtText = "";

      if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
      } else if (Number > 9999999.9999) {
        return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
      } else {
        Number = Number.split(".");
        if (Number[1] && Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2);
        }
        let NumberLen = Number[0].length;
        for (let i = 0; i < NumberLen; i++) {
          let tmp = Number[0].substring(i, i + 1);
          if (tmp !== "0") {
            if (i === NumberLen - 1 && tmp === "1") {
              BahtText += "เอ็ด";
            } else if (i === NumberLen - 2 && tmp === "2") {
              BahtText += "ยี่";
            } else if (i === NumberLen - 2 && tmp === "1") {
              BahtText += "";
            } else {
              BahtText += NumberArray[parseInt(tmp)];
            }
            BahtText += DigitArray[NumberLen - i - 1];
          }
        }
        BahtText += "บาท";
        if (!Number[1] || Number[1] === "0" || Number[1] === "00") {
          BahtText += "ถ้วน";
        } else {
          let DecimalLen = Number[1].length;
          for (let i = 0; i < DecimalLen; i++) {
            let tmp = Number[1].substring(i, i + 1);
            if (tmp !== "0") {
              if (i === DecimalLen - 1 && tmp === "1") {
                BahtText += "เอ็ด";
              } else if (i === DecimalLen - 2 && tmp === "2") {
                BahtText += "ยี่";
              } else if (i === DecimalLen - 2 && tmp === "1") {
                BahtText += "";
              } else {
                BahtText += NumberArray[parseInt(tmp)];
              }
              BahtText += DigitArray[DecimalLen - i - 1];
            }
          }
          BahtText += "สตางค์";
        }
        return BahtText;
      }
    },

    tax_invoice_no() {
      const invoiceNumberString = this.form.invoice_number
        .toString()
        .padStart(4, "0");
      return "L" + this.form.yearmonth + "" + invoiceNumberString;
    },

    with_holding_price() {
      let with_holding_price =
        (this.price_before_vat / 100) * this.form.selectedWithHolding;

      with_holding_price = Math.round(with_holding_price * 100) / 100;

      return with_holding_price;
    },

    after_with_holding_price() {
      return (this.after_discount_price - this.with_holding_price).toFixed(2);
    },
    show_other_box() {
      if (this.form.payment_method === "other") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      form: {
        selectedType: null,
        selectedWithHolding: 0,
        date: new Date().toISOString().substr(0, 10),
        items: [],
        customer_name: null,
        billing_address: null,
        quantity_text: "ระยะเวลา (วัน)",
        price_text: "ราคาต่อวัน",
        total_net_price: 0,
        yearmonth: "",
        invoice_number: "",
        payment_method: "โอนเข้าบัญชี",
      },
      form2: {
        manual_after_discount_price: 0,
        discount_price: 0,
      },
      newRow: {
        product: {
          item_name: "",
          duration: "",
          rental_price: "",
        },
      },
      res: {},
      datepickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      options: [
        { text: "บุคคล", value: "person" },
        { text: "บริษัท", value: "company" },
      ],
      options2: [
        { text: "ไม่", value: 0 },
        { text: "1%", value: 1 },
        { text: "3%", value: 3 },
        { text: "5%", value: 5 },
      ],
      paymentOptions: [
        { text: "โอน", value: "โอนเข้าบัญชี" },
        { text: "เงินสด", value: "เงินสด" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" },
        { text: "อื่นๆ", value: "other" },
      ],
      info: {
        booking: [],
      },
      item: {},
      submitDisable: false,
      other_payment_method: null,
    };
  },
  created() {
    this.get_order();
  },

  methods: {
    CheckNumber(Number) {
      let decimal = false;
      Number = Number.toString();
      Number = Number.replace(/ |,|บาท|฿/gi, "");
      for (let i = 0; i < Number.length; i++) {
        if (Number[i] === ".") {
          decimal = true;
          break;
        }
      }
      if (!decimal) {
        Number += ".00";
      }
      return Number;
    },
    async get_order() {
      const invoice_data = await this.$http({
        method: "GET",
        url: `/tax-invoice/${this.$route.params.id}`,
      });
      if (invoice_data) {
        this.form = invoice_data.data.data;
        this.form2.manual_after_discount_price = this.form.total_net_price;
        this.form2.discount_price = this.form.discount;

        if (this.form.is_payment_method_other === true) {
          this.other_payment_method = this.form.payment_method;
          this.form.payment_method = "other";
        }
      }
    },

    addNewItem() {
      // Add the new row to the data array
      this.form.items.push({ ...this.newRow });

      // Clear the newRow object for the next addition
      this.newRow = {
        product: {
          item_name: "",
          duration: "",
          rental_price: "",
        },
      };
    },

    async editTaxInvoice() {
      this.submitDisable = true;
      this.form.total_price = this.display_sum_total; //รวมเป็นเงิน
      this.form.discount = this.display_discount; // ส่วนลด
      this.form.total_net_price = this.after_discount_price; // จำนวนเงินหลังหักส่วนลด
      this.form.vat = this.vat; // ภาษีมูลค่าเพิ่ม
      this.form.price_before_vat = this.price_before_vat; // ราคาไม่รวมภาษีมูลค่าเพิ่ม
      this.form.with_holding_price = this.with_holding_price; // ยอด หัก ณ ที่จ่าย
      this.form.selectedWithHolding = this.form.selectedWithHolding; // % หัก ณ ที่จ่าย
      this.form.after_with_holding_price = this.after_with_holding_price; // ยอดชำระ
      this.form.is_payment_method_other = this.show_other_box;

      if (this.form.payment_method === "other") {
        this.form.payment_method = this.other_payment_method;
      }
      this.form.price_after_vat =
        parseFloat(this.price_before_vat) + parseFloat(this.vat);
      this.form.thaiBahtText = this.thaiBahtText;
      this.form.tax_invoice_no = this.tax_invoice_no;

      this.$http({
        method: "post",
        url: `/tax-invoice/edit/${this.$route.params.id}`,
        data: this.form,
      })
        .then((x) => {
          this.$router.push({
            name: "tax-invoice-print",
            params: { id: x.data.data._id },
          });
        })
        .catch(() => {
          this.submitDisable = false;
        });
    },

    deleteItem(index) {
      // const index = this.form.items.findIndex((item) => item._id === id);
      if (index !== -1) {
        this.form.items.splice(index, 1);
      }
    },
    computedTotal(item) {
      // Compute the total for the current item
      const temp = parseFloat(item.duration) * parseFloat(item.rental_price);

      if (!temp) {
        return null;
      }
      return temp;
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
<style>
.my-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.my-table th,
.my-table td {
  padding-left: 20px !important;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.my-table th:first-child,
.my-table td:first-child {
  border-left: 1px solid black;
}
.my-table th:last-child,
.my-table td:last-child {
  border-right: 1px solid black;
}
</style>
